import { apiRequest } from "./apiRequest";

interface ContactUsData {
  name: string;
  email: string;
  comments?: string;
}

const send = async (body: ContactUsData) => {
  const { data } = await apiRequest.post("/contactus", body);
  return data;
};

export const ContactUsApi = { send };
